import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useContext, useMemo, useState } from 'react';

import { StickyHeader } from '@jnj/smn-components-web';
import { BootstrapApplicationData } from 'components/bootstrap-application-data';
import MobileRoutingNavigation from 'components/mobile-routing-navigation';
import SupportRequestModal from 'components/support-request-modal';

import { useAppMediaQuery } from 'core/hooks/useAppMediaQuery';
import { getHeaderContentConfiguration } from 'utils/ui/stickyHeaderUtilsUi';
import { scrollToTop } from 'utils/browser/windowUtilsBrowser';

import { authClient } from 'core/auth/authClient';
import { AppContext } from 'core/context/appContext';

import { UserProfileRoutes } from 'core/types/router/commonTypesRouter';

const InformationPage = lazy(() => import('pages/information-page'));
const RecommendationsPage = lazy(() => import('pages/recommendations-page'));

export const App = () => {
  const { i18n } = useContext(AppContext);
  
  const { isDownMd, isDownLg } = useAppMediaQuery();
  
  const [isMobileNavOpen, changeIsMobileNavOpen] = useState(false);
  const [isOpenSupportRequestModal, setIsOpenSupportRequestModal] = useState(false);
  
  const onNavIconClick = () => {
    scrollToTop();
    changeIsMobileNavOpen((prevState) => !prevState);
  }
  
  const onCloseMobileNav = () => {
    scrollToTop();
    changeIsMobileNavOpen(false);
  }
  
  const onOpenSupportRequestModal = () => setIsOpenSupportRequestModal(true);
  
  const onCloseSupportRequestModal = () => setIsOpenSupportRequestModal(false);
  
  const headerContentConfig = useMemo(
    () => getHeaderContentConfiguration(i18n, { logoutFn: authClient.logout, openSupportFn: onOpenSupportRequestModal  }),
    [i18n]
  );
  
  return (
    <BootstrapApplicationData>
      <BrowserRouter>
        <StickyHeader
          isMobile={isDownMd}
          config={headerContentConfig}
          isMobileNavOpen={isMobileNavOpen}
          onNavIconClick={onNavIconClick}
          mobileRoutingNavigation={
            <MobileRoutingNavigation
              onCloseMobileNav={onCloseMobileNav}
            />
          }
        />
        <Suspense>
          <Routes>
            <Route
              path={UserProfileRoutes.information}
              element={<InformationPage isDownLg={isDownLg} isDownMd={isDownMd} onOpenSupportRequestModal={onOpenSupportRequestModal} />}
            />
            <Route
              path={UserProfileRoutes.recommendations}
              element={<RecommendationsPage isDownLg={isDownLg} isDownMd={isDownMd} onOpenSupportRequestModal={onOpenSupportRequestModal} />}
            />
            <Route
              path='*'
              element={<Navigate to={UserProfileRoutes.information}/>}
            />
          </Routes>
        </Suspense>
        <SupportRequestModal
          isModalOpen={isOpenSupportRequestModal}
          onModalClose={onCloseSupportRequestModal}
        />
      </BrowserRouter>
    </BootstrapApplicationData>
  )
}