export enum SupportRequestFormFields {
  questionTheme = 'questionTheme',
  question = 'question'
}

export type SupportRequestFormParams = {
  [SupportRequestFormFields.questionTheme]: string,
  [SupportRequestFormFields.question]: string;
}

export enum SupportRequestFormErrorTypes {
  requiredQuestionTheme = 'requiredQuestionTheme',
  requiredQuestion = 'requiredQuestion',
  isValidMinLengthQuestion = 'isValidMinLengthQuestion',
  isValidMaxLengthQuestion = 'isValidMaxLengthQuestion'
}