import React, { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {
  Icon, IconSizes, IconTypes,
  Button, ButtonStatus, ButtonTypes
} from '@jnj/smn-components-web';

import { useAppSelector } from 'core/redux/store';
import {
  getRecommendationsWithoutUserAction,
} from 'core/redux/selectors/profileSelectors';

import { AppContext } from 'core/context/appContext';

import { UserProfileRoutes } from 'core/types/router/commonTypesRouter';
import { MobileRoutingNavigationProps } from './MobileRoutingNavigation.models';

import './MobileRoutingNavigation.scss';

export const MobileRoutingNavigation:FC<MobileRoutingNavigationProps> = (props) => {
  const { onCloseMobileNav } = props;
  const { i18n } = useContext(AppContext);
  const location = useLocation();
  
  const recommendationCount = useAppSelector(getRecommendationsWithoutUserAction).length;
  
  const isInformation = location.pathname === UserProfileRoutes.information;
  const isRecommendations = location.pathname === UserProfileRoutes.recommendations;

  return (
    <div className='mobile-routing-navigation'>
      <Button
        className='mobile-routing-navigation__button'
        textClassName={classNames(
          'mobile-routing-navigation__button-text',
          isInformation ? 'mobile-routing-navigation__button-text--active' : 'mobile-routing-navigation__button-text--non-active'
        )}
        type={ButtonTypes.routerLink}
        RouterLinkComponent={Link}
        onClick={onCloseMobileNav}
        status={ButtonStatus.profile}
        href={UserProfileRoutes.information}
        text={i18n['mobile-routing-navigation']['button-information']}
        icon={
        <>
          <Icon
            className={classNames('mobile-routing-navigation__button-icon')}
            type={isInformation ? IconTypes.navInfoRed : IconTypes.navInfoBlack}
            size={IconSizes.lg}
          />
        </>
        }
      />
      <Button
        className='mobile-routing-navigation__button'
        textClassName={classNames(
          'mobile-routing-navigation__button-text',
          isRecommendations ? 'mobile-routing-navigation__button-text--active' : 'mobile-routing-navigation__button-text--non-active'
        )}
        type={ButtonTypes.routerLink}
        RouterLinkComponent={Link}
        onClick={onCloseMobileNav}
        status={ButtonStatus.profile}
        href={UserProfileRoutes.recommendations}
        text={i18n['mobile-routing-navigation']['button-recommendations']}
        icon={
          <>
            <Icon
              className={classNames('mobile-routing-navigation__button-icon', isRecommendations ? 'mobile-routing-navigation__button-icon--active' : 'mobile-routing-navigation__button-icon--non-active')}
              type={isRecommendations ? IconTypes.navRecommendationsRed : IconTypes.navRecommendationsBlack}
              size={IconSizes.lg}
            />
            {
              recommendationCount > 0 &&
              <div className='mobile-routing-navigation__button-label'>
                <p className='mobile-routing-navigation__button-label-text'>{`+${recommendationCount}`}</p>
              </div>
            }
          </>
        }
      />
    </div>
  );
}
