import { getHostLocation } from 'utils/browser/locationUtilsBrowser';
import {
  getAbsorbHostByProfileAppHost,
  getAehaHostByProfileAppHost,
  getCinderellaHostByProfileAppHost,
  getWebinarsHostByProfileAppHost
} from 'utils/api-clients/commonUtilsApiClients';

export const getCinderellaHomePageLink = () => {
  const host = getHostLocation();
  const cinderellaHost = getCinderellaHostByProfileAppHost(host);
  
  return `https://${cinderellaHost}`;
}

export const getAbsorbHomePageLink = () => {
  const host = getHostLocation();
  const absorbHost = getAbsorbHostByProfileAppHost(host);
  
  return `https://${absorbHost}/#/catalog`;
}

export const getWebinarsPageLink = () => {
  const host = getHostLocation();
  const webinarsHost = getWebinarsHostByProfileAppHost(host);
  return `https://${webinarsHost}/webinars_jjv`;
}

export const getAehaPageLink = () => {
  const host = getHostLocation();
  const aehaHost = getAehaHostByProfileAppHost(host);

  return `https://${aehaHost}/api/v1/auth/client?authclient=jnj&event_id=8300`;
}
