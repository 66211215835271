import { isLengthLessThanLimit, isRequired } from 'utils/validation/commonRulesValidation';

import {
  QUESTION_LENGTH_MAX_LIMIT,
  QUESTION_LENGTH_MIN_LIMIT,
} from 'core/constants/validation/rulesConstntsValidation';

import { I18nType } from 'core/context/appContext';
import { ResolverResultBuilder } from 'utils/validation/commonUtilsValidation';
import { SupportRequestFormErrorTypes, SupportRequestFormParams } from 'core/types/forms/supportRequestTypesForm';

export const questionThemeValidation = (questionTheme: string, resolverResultBuilder: ResolverResultBuilder, i18n: I18nType) => {
  return resolverResultBuilder
    .validateAndGetResolverResult<'questionTheme', SupportRequestFormErrorTypes>(
      !isRequired(questionTheme),
      {
        field: 'questionTheme',
        type: SupportRequestFormErrorTypes.requiredQuestionTheme,
        message: i18n['support-request-modal']['validation-question-theme-required']
      }
    )
    .setValueToResolverResult<SupportRequestFormParams>({ questionTheme });
};

export const questionValidation = (question: string, resolverResultBuilder: ResolverResultBuilder, i18n: I18nType) => {
  return resolverResultBuilder
    .validateAndGetResolverResult<'question', SupportRequestFormErrorTypes>(
      !isLengthLessThanLimit(question, QUESTION_LENGTH_MAX_LIMIT),
      {
        field: 'question',
        type: SupportRequestFormErrorTypes.isValidMaxLengthQuestion,
        message: i18n['support-request-modal']['validation-question-max-length']
      }
    )
    .validateAndGetResolverResult<'question', SupportRequestFormErrorTypes>(
      isLengthLessThanLimit(question, QUESTION_LENGTH_MIN_LIMIT),
      {
        field: 'question',
        type: SupportRequestFormErrorTypes.isValidMinLengthQuestion,
        message: i18n['support-request-modal']['validation-question-min-length']
      }
    )
    .validateAndGetResolverResult<'question', SupportRequestFormErrorTypes>(
      !isRequired(question),
      {
        field: 'question',
        type: SupportRequestFormErrorTypes.requiredQuestion,
        message: i18n['support-request-modal']['validation-question-required']
      }
    )
    .setValueToResolverResult<SupportRequestFormParams>({ question });
};

export const supportRequestModalFormResolver = (i18n: I18nType) =>
  ({ questionTheme, question }: SupportRequestFormParams) => {
  let resolver = questionThemeValidation(questionTheme, new ResolverResultBuilder(), i18n);
  resolver = questionValidation(question, resolver, i18n);
  
  return resolver
    .getResolverResult();
}