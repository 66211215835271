import { AxiosResponse } from 'axios';

import { keycloakApiClient } from 'core/api-clients/apiClient';
import { getFormDataFromObject, getProfileAPIUrl } from 'utils/api-clients/commonUtilsApiClients';

import {
  applicationJsonContentType,
  multipartFormDataContentType
} from 'core/constants/api-clients/commonConstantsApiClients';

import {
  CurrentUserDeleteData,
  CurrentUserPatchData,
  CurrentUserResponseData,
  SupportRequestSubjectsResponseData,
  SupportRequestsPostData,
  SupportRequestResponseData,
  CurrentUserNotificationsResponseData,
  CurrentUserNotificationsPatchData,
  RecommendationsResponseData, CloseRecommendationsPostData, PostCloseRecommendationsResponseData,
} from 'core/types/api-clients/profileTypesApiClients';

const profileAPIUrl = getProfileAPIUrl();

export const profileApiClient = {
  getCurrent (): Promise<AxiosResponse<CurrentUserResponseData>> {
    return keycloakApiClient.get(`${profileAPIUrl}/current`, { headers: applicationJsonContentType });
  },
  
  patchCurrent(data: CurrentUserPatchData): Promise<AxiosResponse<CurrentUserResponseData>> {
    return keycloakApiClient.patch(`${profileAPIUrl}/current`, data, { headers: applicationJsonContentType });
  },
  
  deleteCurrent(data: CurrentUserDeleteData) {
    return keycloakApiClient.post(`${profileAPIUrl}/current/delete-request`, data, { headers: applicationJsonContentType });
  },
  
  getSupportRequestSubjects(): Promise<AxiosResponse<SupportRequestSubjectsResponseData>> {
    return keycloakApiClient.get(`${profileAPIUrl}/support-request-subjects`, { headers: applicationJsonContentType });
  },
  
  postSupportRequest(data: SupportRequestsPostData): Promise<AxiosResponse<SupportRequestResponseData>> {
    const formData = getFormDataFromObject(data);
    return keycloakApiClient.post(`${profileAPIUrl}/support-request`, formData, { headers: multipartFormDataContentType });
  },
  
  getCurrentUserNotifications(): Promise<AxiosResponse<CurrentUserNotificationsResponseData>> {
    return keycloakApiClient.get(`${profileAPIUrl}/notifications`, { headers: applicationJsonContentType })
  },
  
  patchCurrentUserNotifications(data: CurrentUserNotificationsPatchData): Promise<AxiosResponse<CurrentUserNotificationsResponseData>> {
    return keycloakApiClient.patch(`${profileAPIUrl}/notifications`, data,{ headers: applicationJsonContentType })
  },
  
  getRecommendations(): Promise<AxiosResponse<RecommendationsResponseData>> {
    return keycloakApiClient.get(`${profileAPIUrl}/recommendations`, { headers: applicationJsonContentType })
  },
  
  postCloseRecommendations(data: CloseRecommendationsPostData): Promise<AxiosResponse<PostCloseRecommendationsResponseData>> {
    return keycloakApiClient.post(`${profileAPIUrl}/recommendations/close`, data, { headers: applicationJsonContentType })
  }
};
