export enum Gender {
  male = 'MALE',
  female = 'FEMALE'
}

export interface CurrentUserResponseData {
  id: string,
  firstName: string,
  middleName: string | null,
  lastName: string,
  country: string | null,
  locality: string | null,
  jobTitle: string,
  hasHigherMedicalEducation: boolean,
  dateOfBirth: string | null,
  gender: Gender | null,
  realm: string,
  contactInfo: {
    phone: string,
    phoneVerified: boolean,
    email: string,
    emailVerified: boolean,
    secondaryEmail: string | null,
    secondaryEmailVerified: boolean | null,
  }
}

export interface CurrentUserPatchData {
  firstName?: string,
  middleName?: string | null,
  lastName?: string,
  dateOfBirth?: string | null,
  gender?: Gender | null,
  country?: string | null,
  locality?: string | null,
  jobTitle?: string,
  hasHigherMedicalEducation?: boolean,
  otpChallengeID?: string,
  otpCode?: string,
  contactInfo?: {
    phone?: string,
    phoneVerified?: boolean,
    email?: string,
    emailVerified?: boolean,
    secondaryEmail?: string | null,
    secondaryEmailVerified?: boolean | null,
  }
}

export interface SubscriptionData {
  topic: string,
  topicDisplayName: string,
  enabled: boolean,
}

export interface SubscriptionPatchData {
  topic: string,
  enabled: boolean,
}

export interface CurrentUserNotificationsResponseData {
  phoneNotificationsOptIn: boolean,
  emailNotificationsOptIn: boolean,
  secondaryEmailNotificationsOptIn: boolean
  subscriptions: SubscriptionData[],
}

export interface CurrentUserNotificationsPatchData {
  phoneNotificationsOptIn?: boolean,
  emailNotificationsOptIn?: boolean,
  secondaryEmailNotificationsOptIn?: boolean
  subscriptions?: SubscriptionPatchData[],
}

export interface CurrentUserDeleteData {
  otpChallengeID: string,
  otpCode: string,
}

export type SupportRequestSubjectsResponseData = Array<string>

export interface SupportRequestsPostData {
  subject: string,
  message: string,
  [key: string]: string
}

export interface SupportRequestResponseData {
  id: number,
  userId: string,
  createdAt: string,
}

export enum RecommendationTypeName {
  webinars = 'WEBINARS',
  onlineCourses = 'ONLINE_COURSES',
  onlineMaterials = 'ONLINE_MATERIALS'
}

export interface Recommendation {
  id: number,
  userId: string,
  linkToMaterial: string,
  type: {
    id: number,
    name: RecommendationTypeName,
  },
  title: string,
  description: string,
  createTime: string,
  userActionTime: null | string,
}

export interface RecommendationsResponseData {
  newRecommendations: Recommendation[],
  archivedRecommendations: Recommendation[],
}

export interface CloseRecommendationsPostData {
  recommendationId: number,
}

export type PostCloseRecommendationsResponseData = Omit<Recommendation, 'userId'>

export enum ProfileErrorDataCodes {
  errorUnspecified = 'ERROR_UNSPECIFIED',
  errorBadRequest = 'ERROR_BAD_REQUEST',
  errorOtpRequired = 'ERROR_OTP_REQUIRED',
  errorOtpIncorrect = 'ERROR_OTP_INCORRECT',
  errorDuplicateEmail = 'ERROR_DUPLICATE_EMAIL',
  errorDuplicatePhone = 'ERROR_DUPLICATE_PHONE'
}

export interface ProfileErrorData {
  code: ProfileErrorDataCodes,
  message: boolean,
}
