import React, { FC, useContext, useMemo } from 'react';

import { LoadingSpinner } from '@jnj/smn-components-web';
import ErrorPage from 'pages/error-page';

import { useAuthentication } from 'core/hooks/useAuthentication';
import { useAppDataInit } from 'core/hooks/useAppDataInit';
import { useGetRecaptcha } from 'core/hooks/useGetRecaptcha';

import { useAppSelector } from 'core/redux/store';
import { getAuthError, getIsAuthInitOrLoading, getIsAuthStatusError } from 'core/redux/selectors/authSelectors';
import {
  getIsCurrentUserInitOrInitLoading,
  getIsCurrentUserStatusInitError,
  getCurrentUserError,
  getIsSupportRequestSubjectsStatusInitOrLoading,
  getIsSupportRequestSubjectsStatusError,
  getSupportRequestSubjectsError,
  getIsRecommendationsStatusInitOrLoading,
} from 'core/redux/selectors/profileSelectors';
import {
  getIsSettingsStatusError,
  getIsSettingStatusInitOrLoading,
  getSettingsError
} from 'core/redux/selectors/settingsSelectors';
import {
  getIsRecaptchaError,
  getRecaptchaError
} from 'core/redux/selectors/recaptchaSelectors';

import { getErrorContentByErrorType } from 'utils/ui/bootstrapApplicationDataUtilsUi';
import { AppContext } from 'core/context/appContext';

import { BootstrapApplicationDataProps } from './BootstrapApplicationData.models';

import './BootstrapApplicationData.scss';

export const BootstrapApplicationData: FC<BootstrapApplicationDataProps> = (props) => {
  const { children} = props;
  const { i18n } = useContext(AppContext);

  const isAuthInitOrLoading = useAppSelector(getIsAuthInitOrLoading);
  const isAuthError = useAppSelector(getIsAuthStatusError);
  const authError = useAppSelector(getAuthError);

  const isUserDataInitOrInitLoading = useAppSelector(getIsCurrentUserInitOrInitLoading);
  const isUserDataInitError = useAppSelector(getIsCurrentUserStatusInitError);
  const userError = useAppSelector(getCurrentUserError);

  const isSupportRequestSubjectsStatusInitOrLoading = useAppSelector(getIsSupportRequestSubjectsStatusInitOrLoading);
  const isSupportRequestSubjectsStatusError = useAppSelector(getIsSupportRequestSubjectsStatusError);
  const supportRequestSubjectsError = useAppSelector(getSupportRequestSubjectsError);

  const isSettingStatusInitOrLoading = useAppSelector(getIsSettingStatusInitOrLoading);
  const isSettingsStatusError = useAppSelector(getIsSettingsStatusError);
  const settingsError = useAppSelector(getSettingsError);

  const isRecaptchaError = useAppSelector(getIsRecaptchaError);
  const recaptchaError = useAppSelector(getRecaptchaError);
  
  const isRecommendationsStatusInitOrLoading = useAppSelector(getIsRecommendationsStatusInitOrLoading);
  
  useAuthentication();
  useAppDataInit();
  useGetRecaptcha();
  
  const errorContentByErrorType = useMemo(() => getErrorContentByErrorType({
    i18n,
    isAuthError,
    authError,
    isUserDataInitError,
    userError,
    isSupportRequestSubjectsStatusError,
    supportRequestSubjectsError,
    isSettingsStatusError,
    settingsError,
    isRecaptchaError,
    recaptchaError,
  }), [
    i18n,
    isAuthError,
    authError,
    isUserDataInitError,
    userError,
    isSupportRequestSubjectsStatusError,
    supportRequestSubjectsError,
    isSettingsStatusError,
    settingsError,
    isRecaptchaError,
    recaptchaError
  ]);
  
  if (errorContentByErrorType) {
    return <ErrorPage
      message={errorContentByErrorType.errorMessage}
      notificationBlockContent={{
        type: errorContentByErrorType.notificationType,
        message: errorContentByErrorType.notificationMessage
      }}
      buttonText={errorContentByErrorType.errorButtonText}
      onClickButton={errorContentByErrorType.onClickFn}
    />
  }
  
  return (
    <>
      {isAuthInitOrLoading 
      || isUserDataInitOrInitLoading 
      || isSupportRequestSubjectsStatusInitOrLoading
      || isSettingStatusInitOrLoading
      || isRecommendationsStatusInitOrLoading
        ? <LoadingSpinner
            containerClassName='bootstrap-application-data__loading-spiner'
            message={i18n['bootstrap-application-data']['loading-spinner-message']}
          />
        : children}
    </>
  );
}