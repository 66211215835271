import { createSelector } from '@reduxjs/toolkit';

import { sortByCreateTime, sortByNew } from 'utils/ui/recommendationsUtilsUi';

import { AppState } from 'core/redux/store';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

//Common selectors

export const getProfileReducer = (state: AppState) => state.profileReducer;

export const getCurrentUserStatus = (state: AppState) => getProfileReducer(state).currentUser.status;

export const getProfileData = (state: AppState) => getProfileReducer(state).currentUser.data;

//Current user selectors

export const getIsCurrentUserStatusInit = (state: AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.init;

export const getIsCurrentUserStatusInitLoading = (state:AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.initLoading;

export const getIsCurrentUserStatusLoading = (state:AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.loading;

export const getIsCurrentUserStatusSuccess = (state: AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.success;

export const getIsCurrentUserStatusInitError = (state: AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.initError;

export const getIsCurrentUserStatusError = (state: AppState) => getCurrentUserStatus(state) === ReduxRequestStatus.error;

export const getIsCurrentUserInitOrInitLoading = (state: AppState) => getIsCurrentUserStatusInit(state) || getIsCurrentUserStatusInitLoading(state);

export const getCurrentUserError = (state: AppState) => getProfileReducer(state).currentUser.error;

//Personal info selectors

export const getPersonalInfo = createSelector(
  getProfileData,
  (profileData) => ({
    firstName: profileData?.firstName,
    middleName: profileData?.middleName,
    lastName: profileData?.lastName,
    country: profileData?.country,
    locality: profileData?.locality,
    dateOfBirth: profileData?.dateOfBirth,
    gender: profileData?.gender
  })
);

//Professional info selectors

export const getProfessionalInfo = createSelector(
  getProfileData,
  (profileData) => ({
    jobTitle: profileData?.jobTitle,
    hasHigherMedicalEducation: profileData?.hasHigherMedicalEducation,
  })
);

//Contact info selectors

export const getContactInfo = createSelector(
  getProfileData,
  (profileData) => ({
    phone: profileData?.contactInfo.phone,
    phoneVerified: profileData?.contactInfo.phoneVerified,
    phoneNotificationsOptIn: profileData?.contactInfo.phoneNotificationsOptIn,
    email: profileData?.contactInfo.email,
    emailVerified: profileData?.contactInfo.emailVerified,
    emailNotificationsOptIn: profileData?.contactInfo.emailNotificationsOptIn,
    secondaryEmail: profileData?.contactInfo.secondaryEmail,
    secondaryEmailVerified: profileData?.contactInfo.secondaryEmailVerified,
    secondaryEmailNotificationsOptIn: profileData?.contactInfo.secondaryEmailNotificationsOptIn
  })
);

export const getPhoneEmailData = createSelector(
  getProfileData,
  (profileData) => {
    const contactInfo = profileData?.contactInfo;
    return contactInfo?.phoneVerified ? { isPhone: true, phoneEmail: contactInfo.phone } : { isPhone: false, phoneEmail: contactInfo?.email };
  });

//Subscriptions and notifications selectors

export const getNotificationsStatuses = (state: AppState) => getProfileReducer(state).currentUser.notificationsStatuses;

export const getIsCurrentUserNotificationsStatusInit = (state: AppState) => getNotificationsStatuses(state).notificationsGetStatus === ReduxRequestStatus.init;

export const getIsCurrentUserNotificationsStatusLoading = (state: AppState) => getNotificationsStatuses(state).notificationsGetStatus === ReduxRequestStatus.initLoading;

export const getIsCurrentUserNotificationsStatusError = (state: AppState) => getNotificationsStatuses(state).notificationsGetStatus === ReduxRequestStatus.error;

export const getSubscriptions = (state: AppState) => getProfileData(state)?.subscriptions;

export const getSubscriptionsStatuses = (state: AppState) => getProfileReducer(state).currentUser.subscriptionsStatuses;

export const getNotificationsPatchStatuses = (state: AppState) => getNotificationsStatuses(state).notificationsPatchStatuses;

export const getSubscriptionIsLoadingState = createSelector(
  getSubscriptionsStatuses,
  (state: AppState, topic: string) => topic,
  (subscriptionsStatuses, topics) => subscriptionsStatuses[topics]?.status === ReduxRequestStatus.loading
);

export const getNotificationIsLoadingState = createSelector(
  getIsCurrentUserNotificationsStatusLoading,
  getIsCurrentUserNotificationsStatusError,
  getNotificationsPatchStatuses,
  (state: AppState, topic: string) => topic,
  (
    isGetNotificationStatusLoading,
    isGetNotificationStatusError,
    notificationsStatuses,
    topics
  ) => isGetNotificationStatusLoading || isGetNotificationStatusError || notificationsStatuses[topics]?.status === ReduxRequestStatus.loading
);

export const getSubscriptionOrNotificationIsDisabledState = createSelector(
  getSubscriptionsStatuses,
  getNotificationsPatchStatuses,
  (state: AppState, topic: string) => topic,
  (subscriptionsStatuses, notificationsStatuses, topic) =>
    Object
      .entries(subscriptionsStatuses)
      .some(([key, value]) => key !== topic && value.status === ReduxRequestStatus.loading) ||
    Object
      .entries(notificationsStatuses)
      .some(([key, value]) => key !== topic && value.status === ReduxRequestStatus.loading)
);

export const getSubscriptionWithError = createSelector(
  getSubscriptionsStatuses,
  (subscriptionsStatuses) =>
    Object
      .entries(subscriptionsStatuses)
      .find(([_, value]) => value.status === ReduxRequestStatus.error)
);

export const getNotificationWithError = createSelector(
  getNotificationsPatchStatuses,
  (notificationsStatuses) =>
    Object
      .entries(notificationsStatuses)
      .find(([_, value]) => value.status === ReduxRequestStatus.error)
);

export const getIsAllSubscriptionsIsEnabled = (state: AppState) => !!getSubscriptions(state)?.every(subscription => subscription.enabled);

//Delete profile selectors

export const getCurrentUserDeleteData = (state: AppState) => getProfileReducer(state).currentUser.deleteData;

export const getIsCurrentUserStatusDeleteLoading = (state: AppState) => getCurrentUserDeleteData(state).status === ReduxRequestStatus.loading;

export const getIsCurrentUserStatusDeleteSuccess = (state: AppState) => getCurrentUserDeleteData(state).status === ReduxRequestStatus.success;

export const getIsCurrentUserStatusDeleteError = (state: AppState) => getCurrentUserDeleteData(state).status === ReduxRequestStatus.error;

export const getCurrentUserDeleteError = (state: AppState) => getCurrentUserDeleteData(state).error;

//Support request selectors

export const getUserSupportRequest = (state: AppState) => getProfileReducer(state).supportRequest;

export const getUserSupportRequestSubjects = (state: AppState) => getUserSupportRequest(state).subjects;

export const getSupportRequestStatus = (state: AppState) => getUserSupportRequest(state).status;

export const getSupportRequestSubjectsStatus = (state: AppState) => getUserSupportRequestSubjects(state).status;

export const getIsSupportRequestStatusIsLoading = (state: AppState) => getSupportRequestStatus(state) === ReduxRequestStatus.loading;

export const getIsSupportRequestStatusIsSuccess = (state: AppState) => getSupportRequestStatus(state) === ReduxRequestStatus.success;

export const getSupportRequestError = (state: AppState) => getUserSupportRequest(state).error;

export const getIsSupportRequestSubjectsStatusInit = (state: AppState) => getSupportRequestSubjectsStatus(state) === ReduxRequestStatus.init;

export const getIsSupportRequestSubjectsStatusLoading = (state: AppState) => getSupportRequestSubjectsStatus(state) === ReduxRequestStatus.loading;

export const getIsSupportRequestSubjectsStatusError = (state: AppState) => getSupportRequestSubjectsStatus(state) === ReduxRequestStatus.error;

export const getIsSupportRequestSubjectsStatusInitOrLoading = (state: AppState) => getIsSupportRequestSubjectsStatusInit(state) || getIsSupportRequestSubjectsStatusLoading(state);

export const getSupportRequestSubjectsError = (state: AppState) => getUserSupportRequestSubjects(state).error;

export const getSupportRequestSubjectsData = (state: AppState) => getUserSupportRequestSubjects(state).data ?? [];

//Recommendations selectors

export const getUserRecommendations = (state: AppState) => getProfileReducer(state).currentUser.recommendations;

export const getRecommendationsData = (state: AppState) => getUserRecommendations(state).data;

export const getRecommendationsStatus = (state: AppState) => getUserRecommendations(state).status;

export const getIsRecommendationsStatusInit = (state: AppState) => getRecommendationsStatus(state) === ReduxRequestStatus.init;

export const getIsRecommendationsStatusLoading = (state: AppState) => getRecommendationsStatus(state) === ReduxRequestStatus.loading;

export const getIsRecommendationsStatusError = (state: AppState) => getRecommendationsStatus(state) === ReduxRequestStatus.error;

export const getIsRecommendationsStatusInitOrLoading = (state: AppState) => getIsRecommendationsStatusInit(state) || getIsRecommendationsStatusLoading(state);

export const getSortedNewRecommendations = createSelector(
  getRecommendationsData,
  (recommendationsData) => {
    const newRecommendations = recommendationsData?.newRecommendations ?? [];
    
    return [...newRecommendations].sort(sortByCreateTime).sort(sortByNew);
  }
);

export const getRecommendationsWithoutUserAction = createSelector(
  getRecommendationsData,
  (recommendationsData) => {
    const newRecommendations = recommendationsData?.newRecommendations ?? [];
    
    return newRecommendations.filter(rec => !rec.userActionTime);
  }
);
