import React from 'react';
import { SnackbarContent } from 'components/snackbar-content';

import { AlertSeverityTypes } from '@jnj/smn-components-web';
import { I18nType } from 'core/context/appContext';
import {
  ErrorType,
  InternalErrorTypes,
  RecaptchaErrorTypes,
  ReduxError,
  UserContactInfoErrorTypes
} from 'core/types/redux/commonTypesRedux';
import { useSnackbarReturnType } from 'core/hooks/useSnackbar';

export const getSnackbarMessageWithReloadLink = (message: string, reloadMessage: string) => {
  return <SnackbarContent message={message} reloadMessage={reloadMessage} />
};

export const addCommonSnackbarsByErrorTypes = (i18n: I18nType, error: ReduxError, enqueueSnackbar: useSnackbarReturnType['enqueueSnackbar']) => {
  switch (error.errorType) {
    case ErrorType.client:
      enqueueSnackbar(
        i18n['snackbar-errors']['client-error-message'],
        { variant: AlertSeverityTypes.error }
      );
      break;
    case ErrorType.server:
      enqueueSnackbar(
        i18n['snackbar-errors']['server-error-message'],
        { variant: AlertSeverityTypes.error }
      );
      break;
    case ErrorType.toManyRequests:
      enqueueSnackbar(i18n['otp-snackbar-errors']['too-many-request-error-message'], { variant: AlertSeverityTypes.error });
      break;
    case ErrorType.timeout:
    case ErrorType.unknown:
      enqueueSnackbar(
        getSnackbarMessageWithReloadLink(i18n['snackbar-errors']['timeout-unknown-error-message'], i18n['snackbar-errors']['reload-page-message']),
        { variant: AlertSeverityTypes.error }
      );
      break;
    case InternalErrorTypes.updateData:
      enqueueSnackbar(
        i18n['snackbar-errors']['update-data-error-message'],
        { variant: AlertSeverityTypes.error }
      );
      break;
    case InternalErrorTypes.outOfDateData:
      enqueueSnackbar(
        getSnackbarMessageWithReloadLink(i18n['snackbar-errors']['out-of-date-data-error-message'], i18n['snackbar-errors']['reload-page-message']),
        { variant: AlertSeverityTypes.error }
      );
      break;
    case InternalErrorTypes.notificationsInconsistency:
      enqueueSnackbar(i18n['snackbar-errors']['notifications-inconsistency'], { variant: AlertSeverityTypes.error, autoHideDuration: null });
      break;
    case InternalErrorTypes.notificationsSubscriptionsInconsistency:
      enqueueSnackbar(i18n['snackbar-errors']['notifications-subscriptions-inconsistency'], { variant: AlertSeverityTypes.error, autoHideDuration: null });
      break;
    case UserContactInfoErrorTypes.emailConflict:
      enqueueSnackbar(i18n['snackbar-errors']['email-conflict-error-message'], { variant: AlertSeverityTypes.error });
      break;
    case UserContactInfoErrorTypes.phoneConflict:
      enqueueSnackbar(i18n['snackbar-errors']['phone-conflict-error-message'], { variant: AlertSeverityTypes.error });
      break;
    case RecaptchaErrorTypes.recaptchaBrowserError:
      enqueueSnackbar(
        getSnackbarMessageWithReloadLink(i18n['recaptcha']['browser-error'], i18n['snackbar-errors']['reload-page-message']),
        { variant: AlertSeverityTypes.error }
      );
      break;
    case RecaptchaErrorTypes.noResponseWhenRecaptchaRequested:
      enqueueSnackbar(
        getSnackbarMessageWithReloadLink(i18n['recaptcha']['no-response'], i18n['snackbar-errors']['reload-page-message']),
        { variant: AlertSeverityTypes.error }
      );
      break;
    case RecaptchaErrorTypes.getRecaptchaTokenError:
      enqueueSnackbar(
        getSnackbarMessageWithReloadLink(i18n['recaptcha']['get-token-error'], i18n['snackbar-errors']['reload-page-message']),
        { variant: AlertSeverityTypes.error }
      );
      break;
    default:
      break;
  }
};