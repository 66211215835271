import { createAction } from '@reduxjs/toolkit';

import { ProfileConstants } from 'core/redux/constants/profileConstant';
import {
  CurrentUserResponseData,
  SupportRequestSubjectsResponseData,
  SupportRequestResponseData,
  CurrentUserPatchData,
  CurrentUserNotificationsResponseData, RecommendationsResponseData, PostCloseRecommendationsResponseData,
} from 'core/types/api-clients/profileTypesApiClients';
import { ReduxError } from 'core/types/redux/commonTypesRedux';
import {
  PatchCurrentUserNotificationsFailureData, PatchCurrentUserNotificationsSuccessData,
  PatchCurrentUserSubscriptionsFailureData, PatchCurrentUserSubscriptionsSuccessData
} from 'core/types/redux/profileTypesRedux';

export const getCurrentUserStart = createAction(ProfileConstants.GET_CURRENT_USER_START);
export const getCurrentUserSuccess = createAction<CurrentUserResponseData>(ProfileConstants.GET_CURRENT_USER_SUCCESS);
export const getCurrentUserFailure = createAction<ReduxError>(ProfileConstants.GET_CURRENT_USER_FAILURE);

export const patchCurrentUserStart = createAction(ProfileConstants.PATCH_CURRENT_USER_START);
export const patchCurrentUserSuccess = createAction<CurrentUserPatchData>(ProfileConstants.PATCH_CURRENT_USER_SUCCESS);
export const patchCurrentUserFailure = createAction<ReduxError>(ProfileConstants.PATCH_CURRENT_USER_FAILURE);
export const updateCurrentUser = createAction<CurrentUserResponseData>(ProfileConstants.UPDATE_CURRENT_USER);

export const getCurrentUserNotificationsStart = createAction(ProfileConstants.GET_CURRENT_USER_NOTIFICATIONS_START);
export const getCurrentUserNotificationsSuccess = createAction<CurrentUserNotificationsResponseData>(ProfileConstants.GET_CURRENT_USER_NOTIFICATIONS_SUCCESS);
export const getCurrentUserNotificationsFailure = createAction<ReduxError>(ProfileConstants.GET_CURRENT_USER_NOTIFICATIONS_FAILURE);

export const patchCurrentUserNotificationsStart = createAction<string>(ProfileConstants.PATCH_CURRENT_USER_NOTIFICATIONS_START);
export const patchCurrentUserNotificationsSuccess = createAction<PatchCurrentUserNotificationsSuccessData>(ProfileConstants.PATCH_CURRENT_USER_NOTIFICATIONS_SUCCESS);
export const patchCurrentUserNotificationsFailure = createAction<PatchCurrentUserNotificationsFailureData>(ProfileConstants.PATCH_CURRENT_USER_NOTIFICATIONS_FAILURE);
export const resetCurrentUserNotificationsError = createAction<string>(ProfileConstants.RESET_CURRENT_USER_NOTIFICATIONS_ERROR);

export const patchCurrentUserSubscriptionsStart = createAction<string>(ProfileConstants.PATCH_CURRENT_USER_SUBSCRIPTIONS_START);
export const patchCurrentUserSubscriptionsSuccess = createAction<PatchCurrentUserSubscriptionsSuccessData>(ProfileConstants.PATCH_CURRENT_USER_SUBSCRIPTIONS_SUCCESS);
export const patchCurrentUserSubscriptionsFailure = createAction<PatchCurrentUserSubscriptionsFailureData>(ProfileConstants.PATCH_CURRENT_USER_SUBSCRIPTIONS_FAILURE);
export const resetCurrentUserSubscriptionsError = createAction<string>(ProfileConstants.RESET_CURRENT_USER_SUBSCRIPTIONS_ERROR);

export const deleteCurrentUserStart = createAction(ProfileConstants.DELETE_CURRENT_USER_START);
export const deleteCurrentUserSuccess = createAction(ProfileConstants.DELETE_CURRENT_USER_SUCCESS);
export const deleteCurrentUserFailure = createAction<ReduxError>(ProfileConstants.DELETE_CURRENT_USER_FAILURE);

export const getProfileSupportSubjectsStart = createAction(ProfileConstants.GET_PROFILE_SUPPORT_SUBJECTS_START);
export const getProfileSupportSubjectsSuccess = createAction<SupportRequestSubjectsResponseData>(ProfileConstants.GET_PROFILE_SUPPORT_SUBJECTS_SUCCESS);
export const getProfileSupportSubjectsFailure = createAction<ReduxError>(ProfileConstants.GET_PROFILE_SUPPORT_SUBJECTS_FAILURE);

export const postProfileSupportRequestStart = createAction(ProfileConstants.POST_PROFILE_SUPPORT_REQUEST_START);
export const postProfileSupportRequestSuccess = createAction<SupportRequestResponseData>(ProfileConstants.POST_PROFILE_SUPPORT_REQUEST_SUCCESS);
export const postProfileSupportRequestFailure = createAction<ReduxError>(ProfileConstants.POST_PROFILE_SUPPORT_REQUEST_FAILURE);

export const getProfileRecommendationsStart = createAction(ProfileConstants.GET_PROFILE_RECOMMENDATIONS_START);
export const getProfileRecommendationsSuccess = createAction<RecommendationsResponseData>(ProfileConstants.GET_PROFILE_RECOMMENDATIONS_SUCCESS);
export const getProfileRecommendationsFailure = createAction<ReduxError>(ProfileConstants.GET_PROFILE_RECOMMENDATIONS_FAILURE);

export const postCloseProfileRecommendationsStart = createAction(ProfileConstants.POST_CLOSE_PROFILE_RECOMMENDATIONS_START);
export const postCloseProfileRecommendationsSuccess = createAction<PostCloseRecommendationsResponseData>(ProfileConstants.POST_CLOSE_PROFILE_RECOMMENDATIONS_SUCCESS);
export const postCloseProfileRecommendationsFailure = createAction<ReduxError>(ProfileConstants.POST_CLOSE_PROFILE_RECOMMENDATIONS_FAILURE);
