import { getOnlyDigits } from 'utils/common/stringUtilsCommon';
import { VALID_PHONE_NUMBER_LENGTH } from 'core/constants/validation/rulesConstntsValidation';
import { EMPTY_DATE_VALUE } from 'core/constants/ui/personalInfoModalConstantsUi';

export const isPhoneNumber = (value: string) => RegExp(/^[+\-()0-9\s]+$/).test(value);

export const isValidEmail = (value: string) => RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/).test(value);

export const isRequired = (value: string) => value.trim() !== '';

export const isRequiredBoolean = (value: boolean) => value;

export const isPhoneFormatIsValid = (value: string) => getOnlyDigits(value).length === VALID_PHONE_NUMBER_LENGTH;

// https://en.wikipedia.org/wiki/%2B7
export const isPhoneNumberIsRussian = (value: string) => RegExp(/^7([3489])/).test(getOnlyDigits(value));

export const isLengthLessThanLimit = (value: string, lengthLimit: number) => value.length <= lengthLimit;

export const isNameAllowedCharacters = (value: string) => RegExp(/^[a-zA-Z\u0400-\u04FF \-\x27]*$/gu).test(value);

export const isEmptyDateOfBirths = (value: string) => value === '' || value === EMPTY_DATE_VALUE;

export const isDateOfBirthsValid = (value: string) => {
  if (isEmptyDateOfBirths(value)) {
    return true;
  }

  if (!RegExp(/^\d{4}-\d{1,2}-\d{1,2}$/).test(value)) {
    return false;
  }

  const [year, month, day] = value.split('-').map(Number);
  
  if (typeof year === 'undefined' || typeof month === 'undefined' || typeof day === 'undefined') {
    return false;
  }
  
  const date = new Date(year, month - 1, day);

  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return false;
  }

  return date <= new Date();
}

export const isMatchPatterns = (value: string, patterns: Array<string>) => patterns.some((pattern) => RegExp(pattern, 'i').test(value));
