import React, { FC } from 'react';

import { getHrefLocation } from 'utils/browser/locationUtilsBrowser';

import { SnackbarContentProps } from 'components/snackbar-content/SnackbarContent.models';

import './SnackbarContent.scss';

export const SnackbarContent: FC<SnackbarContentProps> = (props) => {
  const { message, reloadMessage } = props;

  return (
    <>
      <span>{message}</span>
      <a className='snackbar-content__snackbar-link' href={getHrefLocation()}>{reloadMessage}</a>
    </>
  );
};
