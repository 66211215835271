import { useMemo, useCallback, ReactNode, useContext } from 'react';
import {
  useSnackbar as useNotistackSnackbar,
  OptionsObject,
  closeSnackbar,
} from 'notistack';

import { Alert, AlertSeverityTypes } from '@jnj/smn-components-web';

import { AppContext } from 'core/context/appContext';

export type useSnackbarReturnType = ReturnType<typeof useSnackbar>

export const useSnackbar = () => {
  const { i18n } = useContext(AppContext);
  const snackbarMethods = useNotistackSnackbar();

  const enqueueSnackbar = useCallback((
    message: ReactNode,
    options: OptionsObject | Partial<{ variant: AlertSeverityTypes }>,
  ) => {
    const snackbarId = snackbarMethods.enqueueSnackbar(message, {
      ...options,
      content: () => {
        const { variant } = options;

        return (
          <Alert
            severity={variant as AlertSeverityTypes}
            data-testid={`${variant}-snackbar`}
            message={message}
            onAction={() => closeSnackbar(snackbarId)}
            ariaLabel={i18n['alert']['close']}
          />
        );
      },
    });
  }, [snackbarMethods, i18n]);

  return useMemo(() => ({
    ...snackbarMethods,
    enqueueSnackbar,
  }), [snackbarMethods, enqueueSnackbar]);
};
