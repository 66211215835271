import { Recommendation as RecommendationType } from 'core/types/api-clients/profileTypesApiClients';

export const sortByCreateTime = (a: RecommendationType, b: RecommendationType) => {
  const dateA = new Date(a.createTime);
  const dateB = new Date(b.createTime);
  
  if (dateA > dateB) {
    return -1;
  }
  if (dateA < dateB) {
    return 1;
  }
  return 0;
}

export const sortByNew = (a: RecommendationType, b: RecommendationType) => {
  if (!a.userActionTime && b.userActionTime) {
    return -1;
  }

  if (a.userActionTime && !b.userActionTime) {
    return 1;
  }

  return 0;
}