export enum CinderellaHosts {
  dev = 'dev.jjvc.ru',
  lab = 'lab.jjvc.ru',
  test = 'test.jjvc.ru',
  staging = 'stage.jnjvisioncare.ru.itrinno.com',
  prod = 'jjvc.ru',
}

export enum KeycloakHosts {
  dev = 'id.dev.jjvc.ru',
  lab = 'id.lab.jjvc.ru',
  test = 'id.test.jjvc.ru',
  staging = 'id.staging.jjvc.ru',
  prod = 'id.jjvc.ru',
}

export enum ProfileAppHosts {
  dev = 'profile.dev.jjvc.ru',
  lab = 'profile.lab.jjvc.ru',
  test = 'profile.test.jjvc.ru',
  staging = 'profile.staging.jjvc.ru',
  prod = 'profile.jjvc.ru',
}

export enum ProfileAPIHosts {
  dev = 'profile.dev.jjvc.ru/api',
  lab = 'profile.lab.jjvc.ru/api',
  test = 'profile.test.jjvc.ru/api',
  staging = 'profile.staging.jjvc.ru/api',
  prod = 'profile.jjvc.ru/api',
}

export enum AbsorbHosts {
  dev = 'saml-sp.dev.jjvc.ru',
  lab = 'saml-sp.lab.jjvc.ru',
  test = 'saml-sp.test.jjvc.ru',
  staging = 'jjvru.sandbox.myabsorb.com',
  prod = 'jjvru.myabsorb.com',
}

export enum AehaHost {
  staging = 'api-service.dev.pointreg.ru',
  prod = 'api.pointreg.ru',
}