import { ResolverResult } from 'react-hook-form';
import { ErrorResultArguments } from 'core/types/validation/commonTypesValidation';

export const getFormResolverErrorResult = <T extends string, V>( field: T, type: V, message: string) => {
  return {
    [field]: {
      type: type,
      message: message
    }
  }
}

export const isResolverResultHaveNoErrors = (result: ResolverResult) => Object.keys(result.errors).length === 0;

export const setErrorToResolverResult = (result: ResolverResult, error: ReturnType<typeof getFormResolverErrorResult>) =>
  ({...result, errors: {...result.errors, ...error}});

export const setValueToResolverResult = <T>(result: ResolverResult, value: Partial<T>) =>
  ({...result, values: {...result.values, ...value }});

export class ResolverResultBuilder {
  resolverResult: ResolverResult;
  
  constructor(resolverResult = { values: {}, errors: {} }) {
    this.resolverResult = resolverResult
  }
  
  validateAndGetResolverResult<T extends string, V>(isErrorResult: boolean, errorResultArguments: ErrorResultArguments<T, V>) {
    if (isErrorResult) {
      const error = getFormResolverErrorResult<T, V>(
        errorResultArguments.field,
        errorResultArguments.type,
        errorResultArguments.message,
      );
      
      this.resolverResult = setErrorToResolverResult(this.resolverResult, error);
    }
    
    return this;
  }
  
  setValueToResolverResult<T>(value: Partial<T>) {
    if (isResolverResultHaveNoErrors(this.resolverResult)) {
      this.resolverResult = setValueToResolverResult<T>(this.resolverResult, value);
    }
    
    return this;
  }
  
  getResolverResult() {
    return this.resolverResult
  }
}
