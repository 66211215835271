import { useContext, useEffect } from 'react';
import { useSnackbar } from 'core/hooks/useSnackbar';

import { useAppSelector } from 'core/redux/store';
import {
  getIsSupportRequestStatusIsSuccess,
  getSupportRequestError
} from 'core/redux/selectors/profileSelectors';

import { addCommonSnackbarsByErrorTypes } from 'utils/hooks/snackbarUtilsHooks';
import { AppContext } from 'core/context/appContext';

import { AlertSeverityTypes } from '@jnj/smn-components-web';

export const useSupportRequestSnackbars = (successMessage: string) => {
  const { i18n } = useContext(AppContext);
  
  const supportRequestError = useAppSelector(getSupportRequestError);
  const isSupportRequestStatusIsSuccess = useAppSelector(getIsSupportRequestStatusIsSuccess);
  
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    if (supportRequestError) {
      addCommonSnackbarsByErrorTypes(i18n, supportRequestError, enqueueSnackbar);
    }
    
    if (isSupportRequestStatusIsSuccess) {
      enqueueSnackbar(successMessage, { variant: AlertSeverityTypes.success });
    }
  }, [supportRequestError, isSupportRequestStatusIsSuccess, successMessage, enqueueSnackbar, i18n]);
}