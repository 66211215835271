import React, { FC, useContext, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  ModalComponent,
  Button, ButtonStatus, ButtonTypes,
  ControlTextField,
  ControlSelectField
} from '@jnj/smn-components-web';


import { usePrevious } from 'core/hooks/usePrevious';
import { useSupportRequestSnackbars } from 'core/hooks/useSupportRequestSnackbars';
import { useAppDispatch, useAppSelector } from 'core/redux/store';

import { postProfileSupportRequest } from 'core/redux/thunks/profileThunks';
import {
  getContactInfo,
  getIsSupportRequestStatusIsLoading,
  getIsSupportRequestStatusIsSuccess,
  getSupportRequestSubjectsData
} from 'core/redux/selectors/profileSelectors';

import { replaceVariable } from 'utils/common/i18nUtilsCommon';
import { supportRequestModalFormResolver } from 'utils/validation/supportRequestFormUtilsValidation';
import { getQuestionThemeItems, prepareSupportRequestFormParams } from 'utils/ui/supportRequestModalUtilsUi';
import { AppContext } from 'core/context/appContext';

import { SupportRequestFormFields, SupportRequestFormParams } from 'core/types/forms/supportRequestTypesForm';
import { SupportRequestModalProps } from './SupportRequestModal.models';

import './SupportRequestModal.scss';

const SupportRequestSelectField = ControlSelectField<SupportRequestFormParams>();

export const SupportRequestModal: FC<SupportRequestModalProps> = (props) => {
  const {
    isModalOpen,
    onModalClose,
  } = props;
  const { i18n } = useContext(AppContext);

  const dispatch = useAppDispatch();

  const { email = '' } = useAppSelector(getContactInfo);
  const isSupportRequestStatusIsSuccess = useAppSelector(getIsSupportRequestStatusIsSuccess);
  const isSupportRequestLoading = useAppSelector(getIsSupportRequestStatusIsLoading);
  const prevIsSupportRequestLoading = usePrevious(isSupportRequestLoading);
  const supportRequestSubjectsData = useAppSelector(getSupportRequestSubjectsData);

  const questionThemeItems = useMemo(() => getQuestionThemeItems(supportRequestSubjectsData), [supportRequestSubjectsData]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      questionTheme: '',
      question: '',
    },
    resolver: supportRequestModalFormResolver(i18n),
  });
  
  useSupportRequestSnackbars(replaceVariable(i18n['support-request-modal']['success-snackbar-message'], '{{email}}', email))

  useEffect(() => {
    if (prevIsSupportRequestLoading && !isSupportRequestLoading) {
      onModalClose();
    }
  }, [prevIsSupportRequestLoading, isSupportRequestLoading, onModalClose]);

  useEffect(() => {
    if (isSupportRequestStatusIsSuccess) {
      reset();
    }
  }, [isSupportRequestStatusIsSuccess, reset]);
  
  const onFormSubmit = (params: SupportRequestFormParams) => {
    dispatch(postProfileSupportRequest(prepareSupportRequestFormParams(params)));
  }
  
  return (
    <ModalComponent
      className='support-request-modal'
      isOpen={isModalOpen}
      isLoading={isSupportRequestLoading}
      onClose={onModalClose}
      headerContent={<h2 className='support-request-modal__header'>{i18n['support-request-modal']['header']}</h2>}
      buttonAriaLabel={i18n['support-request-modal']['close-button-aria-label']}
    >
      <p
        className='support-request-modal__body'
        dangerouslySetInnerHTML={{ __html: replaceVariable(i18n['support-request-modal']['body'], '{{email}}', email) }}
      />
        <form
          className='support-request-modal__form'
          data-testid='contact-info-step-form'
          onSubmit={handleSubmit(onFormSubmit)}>
          <SupportRequestSelectField
            className='support-request-modal__field'
            control={control}
            placeholder={i18n['support-request-modal']['question-theme-placeholder']}
            name={SupportRequestFormFields.questionTheme}
            errorMessage={errors?.questionTheme?.message}
            label={i18n['support-request-modal']['question-theme-label']}
            menuItems={questionThemeItems}
          />
          <ControlTextField
            className='support-request-modal__field'
            control={control}
            placeholder={i18n['support-request-modal']['question-placeholder']}
            name={SupportRequestFormFields.question}
            errorMessage={errors?.question?.message}
            label={i18n['support-request-modal']['question-label']}
            multiline
            minRows={3}
            autoComplete='off'
          />
          <div className='support-request-modal__buttons-bloc'>
            <Button
              className='support-request-modal__button'
              type={ButtonTypes.submit}
              status={ButtonStatus.primary}
              text={i18n['support-request-modal']['primary-button-text']}
            />
            <Button
              className='support-request-modal__button'
              type={ButtonTypes.button}
              status={ButtonStatus.secondary}
              onClick={onModalClose}
              text={i18n['support-request-modal']['secondary-button-text']}
            />
          </div>
        </form>
    </ModalComponent>
  )
}