import { I18nType } from 'core/context/appContext';
import { IconTypes, HeaderContentConfig } from '@jnj/smn-components-web';
import {
  getAbsorbHomePageLink,
  getAehaPageLink,
  getCinderellaHomePageLink,
  getWebinarsPageLink
} from 'utils/ui/navigationUtilsUi';

export const getHeaderContentConfiguration = (
  i18n: I18nType,
  { logoutFn, openSupportFn }: { logoutFn: () => void, openSupportFn: () => void }
): HeaderContentConfig => ({
  header: {
    closeIcon: {
      ariaLabel: i18n['mobile-header']['icon-aria-label'],
    }
  },
  navigation: {
    buttons: [
      {
        isMenu: false,
        showDesktopIcon: true,
        iconType: IconTypes.navSupport,
        text: i18n['navigation']['tech-support-button-text'],
        fn: openSupportFn,
      },
      {
        isMenu: true,
        text: i18n['navigation']['resources-header'],
        children: [
          {
            isMenu: false,
            showDesktopIcon: true,
            iconType: IconTypes.JaJIcon,
            text: i18n['navigation']['first-resource-button-text'],
            href: getCinderellaHomePageLink(),
          },
          {
            isMenu: false,
            showDesktopIcon: true,
            iconType: IconTypes.absorbIcon,
            text: i18n['navigation']['second-resource-button-text'],
            href: getAbsorbHomePageLink(),
          },
          {
            isMenu: false,
            showDesktopIcon: true,
            iconType: IconTypes.JaJIcon,
            text: i18n['navigation']['third-resource-button-text'],
            href: getWebinarsPageLink(),
          },
          {
            isMenu: false,
            showDesktopIcon: true,
            iconType: IconTypes.aehaIcon,
            text: i18n['navigation']['fourth-resource-button-text'],
            href: getAehaPageLink(),
          }
        ]
      },
      {
        isMenu: false,
        showDesktopIcon: false,
        iconType: IconTypes.navExit,
        text: i18n['navigation']['logout-button-text'],
        fn: logoutFn,
      }
    ]
  }
});
